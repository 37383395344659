<template>
  <AprilBody :hidden-header="withOffers">
    <PopinMaintenance v-if="stepsData.family.maintenance_mode" />
    <div class="offers-body">
      <div
        class="offers-heading-container"
        :class="{
          '-with-offers': withOffers,
          '-with-offset': dateChanged,
        }"
      >
        <foundations-ui-wrapper>
          <div id="offers-head" class="offers-heading" />
        </foundations-ui-wrapper>
      </div>

      <div class="offers-content">
        <foundations-ui-wrapper>
          <NuxtPage />
        </foundations-ui-wrapper>
      </div>

      <foundations-ui-wrapper>
        <div id="offers-footer" />
      </foundations-ui-wrapper>
    </div>

    <div id="offers-sticky" class="sticky-container" />
    <div id="offers-drawers" />
  </AprilBody>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { startsWith } from 'lodash' /* eslint-disable-line import/named */
import { pushPageView } from '~/plugins/gtm.client'
import { type RootState } from '~/store/state'
import { type Offer } from '~/types/offers'
import { setValueInCookie } from '~/utils/cookies'

export default defineNuxtComponent({
  name: 'LayoutOffers',
  setup: () => {
    const i18n = useI18n()
    const router = useRouter()
    const route = useRoute()

    useHead({
      htmlAttrs: {
        lang: i18n.locale,
      },
    })

    watch(
      () => route.fullPath,
      () => {
        pushPageView()
      }
    )

    return {
      router,
      route,
    }
  },
  computed: {
    ...(mapGetters({
      dateChanged: 'tgp/dateChanged',
      offers: 'offers',
      stepsData: 'prismic/stepsData',
    }) as {
      dateChanged: () => RootState['tgp']['dateChanged']
      offers: () => RootState['offers']
      stepsData: () => RootState['prismic']['stepsData']
    }),
    withOffers() {
      // TODO : this is not a good way to update the layout as the route can change
      // before the next layout is displayed, and this results in a flickering effect
      // we should have instead a props, or a store value to update the layout
      if (
        startsWith(this.route.name as string, 'offres') ||
        startsWith(this.route.name as string, 'souscription-assure-principal')
      ) {
        const offers = this.offers as Offer[] | undefined

        return offers && offers.length > 0
      }

      return false
    },
    withRecap() {
      return startsWith(this.route.name as string, 'recapitulatif')
    },
  },
  created() {
    // since component Nuxt does not trigger events emitted by sub-components, bind on Nuxt event
    // @see https://stackoverflow.com/a/67817642
    this.$bus.$on('commit-project', ($event: ProjectPayload) => {
      this.onCommit($event)
    })
  },
  mounted() {
    // unset steps on this layout
    this.$store.dispatch('steps/setCurrentStep', undefined)
  },
  beforeDestroy() {
    // removing eventBus listener
    this.$bus.$off('commit-project')
  },
  methods: {
    // append payload values to the cookie on commit
    onCommit(payload: ProjectPayload) {
      const cookie = this.$cookies('__april_project')
      let { value: cookieValue } = cookie

      Object.entries(payload).forEach(([key, value]) => {
        cookieValue = setValueInCookie(
          key as ProjectKey,
          value,
          cookieValue || ''
        )
      })

      cookie.value = cookieValue
    },
  },
})
</script>

<style lang="scss" scoped>
.offers-body {
  min-height: calc(100vh - 291px);

  @media #{$mq-medium} {
    min-height: calc(100vh - april-rem(230));
  }

  .offers-heading-container {
    background-color: $color-dark-blue;
    color: $color-white;
    padding: april-rem($spacing-l);
    width: 100vw;

    @media #{$mq-medium} {
      padding: april-rem($spacing-2xl) 0;
    }

    &.-with-offers {
      @media #{$mq-medium} {
        height: 656px;
        margin-bottom: -490px;
        padding: april-rem($spacing-2xl) 0 0 0;
      }

      /* when a molecules-notification-card is displayed */
      &.-with-offset {
        @media #{$mq-medium} {
          margin-bottom: -340px;
        }

        @media #{$mq-large} {
          margin-bottom: -360px;
        }
      }
    }
  }

  .offers-content {
    overflow: hidden;
  }
}
</style>
